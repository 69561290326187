import React, { useState } from 'react';

function Checkbox({ name, label, selected, saveUpdate, spanClass }) {
  const [isChecked, setIsChecked] = useState(selected);

  const handleInputChange = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    saveUpdate(null, name, newValue);
  };

  return (
    <span className={`${spanClass}`}>
      <span className="label">{label}</span>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleInputChange}
        style={{ width: 'unset' }}
      />
    </span>
  );
}

export default Checkbox;
