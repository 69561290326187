// Import Libraries
import React from 'react';

// Construct Component
function SecureScriptFooter() {
  return (
    <div>
      <div>
        Your script is secure with us. We will not use it to train the AI.
        <a
          className="linkText"
          style={{ padding: '0px 5px' }}
          target="_blank"
          href="https://www.shotlistai.com/faq"
        >
          Read More
        </a>
      </div>
    </div>
  );
}

export default SecureScriptFooter;
