// Import Libraries
import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

// Import Context
import ScriptContext from '../../../context/Script.js';
import SessionContext from '../../../context/Session';

// Import Services
import ScriptService from '../../../services/scriptService';

// Construct Component
const SceneGenerateSection = ({ scene, view, setView }) => {
  const { script } = useContext(ScriptContext);
  const { isMobile } = useContext(SessionContext);
  const [floatDirection, setFloatDirection] = useState(isMobile ? 'left' : 'right');

  const generateShots = async (sceneNumber) => {
    const foundSceneIndex = script.scenes.findIndex((original) => original.id === sceneNumber);

    if (foundSceneIndex !== -1) {
      try {
        await ScriptService.getShotListForScene(script.id, script.scenes[foundSceneIndex].id);
      } catch (error) {
        console.error('Error generating shots:', error);
      }

      setView((prevView) => ({ ...prevView, updateDictionary: true }));
    }
  };

  function changePdfView() {
    setView({ ...view, pdfActive: !view.pdfActive });
  }

  useEffect(() => {
    setFloatDirection(isMobile ? 'left' : 'right');
  }, [isMobile]);

  return (
    <>
      {scene?.ai_status <= 1 ? (
        <>
          {scene.isGeneratingShots === true || scene.ai_status === 1 ? (
            <span className="ThreePortionFlex">
              <img
                src="/Spinner-2.9s-297px.gif"
                className="loadingIcon icon"
                style={{ float: floatDirection, height: '40px', width: '40px' }}
                alt="Loading.."
              />
            </span>
          ) : scene.scene_text && script?.features.toLowerCase() !== 'basic' ? (
            <span className={`${isMobile ? 'FullWidthFlex' : 'ThreePortionFlex'}`}>
              <button
                className={`buttonClone`}
                style={{ marginLeft: isMobile ? '0px' : '7px', float: floatDirection }}
                onClick={() => generateShots(scene.id)}
              >
                Generate
              </button>
            </span>
          ) : view.uploadedFile && (!scene.scene_text || scene.scene_text === '') ? (
            <span className="orangeText" style={{ float: floatDirection, paddingRight: '20px' }}>
              No Scene Text
            </span>
          ) : (
            <span className={`${isMobile ? 'FullWidthFlex' : 'ThreePortionFlex'}`}>
              <button
                className={`buttonClone`}
                style={{ marginLeft: isMobile ? '0px' : '7px', float: floatDirection }}
                disabled="disabled"
              >
                Generate
              </button>
            </span>
          )}
        </>
      ) : view.uploadedFile && script.scenes[view.sceneIndex]?.id == scene.id ? (
        <button
          className={`buttonClone ${!isMobile ? 'TwoPortionFlex' : ''}`}
          onClick={() => changePdfView()}
          style={{ float: floatDirection, padding: '5px' }}
        >
          <img src="/icons/PdfIcon_White.png" className="Padded SmallIcon" alt="List View Icon" />
          <FontAwesomeIcon
            icon={view.pdfActive ? faCaretUp : faCaretDown}
            className={`sizeUp SmallIcon`}
            style={{ color: '#ffffff', marginTop: '5px', paddingRight: '5px' }}
          />
        </button>
      ) : script.scenes[view.sceneIndex]?.id !== scene.id && scene.shot_list ? (
        <span className="greenText" style={{ float: floatDirection, paddingRight: '20px' }}>
          Ready
        </span>
      ) : null}
    </>
  );
};

export default SceneGenerateSection;
