// Libraries
import React from 'react';

// Construct Component
const UserCreditBar = ({ level, availableCredits }) => {
  return (
    <div
      className="fullWidthContainer"
      style={{
        background: 'white',
        marginTop: '20px',
        marginBottom: '20px',
        padding: '20px',
        borderLeft: '3px solid #7A64EB',
        color: '#7A64EB',
        width: 'calc(100vw - 100px)',
      }}
    >
      <span className="sizeUp">
        You can generate a shotlist for
        {availableCredits ? (
          <>
            {availableCredits.proFreemium ? (
              <span className="Padded">
                10 scenes of your first project
                {availableCredits.basicFreemium || availableCredits.totalCredits ? ', ' : null}
              </span>
            ) : null}
            {availableCredits.basicFreemium ? (
              <span className="Padded">
                5 scenes of your second project
                {availableCredits.totalCredits ? ' and ' : null}
              </span>
            ) : null}
            {level !== 'pro' ? (
              <span className="Padded">Upgrade to Pro for more options.</span>
            ) : availableCredits.totalCredits >= 100 ? (
              <>
                {availableCredits.basicFreemium || availableCredits.proFreemium ? ' and ' : null}
                <span className="Padded">
                  {' '}
                  {availableCredits.totalCredits / 100} more scripts this month
                </span>
              </>
            ) : !availableCredits.basicFreemium && !availableCredits.proFreemium ? (
              <span className="Padded">0 more scripts this month</span>
            ) : null}
          </>
        ) : (
          <span className="Padded">0 more scripts this month</span>
        )}
      </span>
    </div>
  );
};

export default UserCreditBar;
