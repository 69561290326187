// Import Libraries
import React, { useState } from 'react';

// Import Components
import ScriptExport from '../../Modals/ScriptExport';

// Construct Component
const ExportProjectButton = () => {
  const [scriptModalIsOpen, setScriptModalIsOpen] = useState(false);

  const exportScript = () => {
    setScriptModalIsOpen(true);
  };

  return (
    <>
      <button className="buttonGray" style={{ float: 'right' }} onClick={exportScript}>
        <img
          src="/icons/ExportIcon_Black.png"
          className="Padded SmallIcon"
          alt="Export Icon"
          style={{ width: '15px' }}
        />
        Export Shotlist
      </button>
      <ScriptExport modalIsOpen={scriptModalIsOpen} setModalIsOpen={setScriptModalIsOpen} />
    </>
  );
};

export default ExportProjectButton;
