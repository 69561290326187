function buildParams(method, body) {
  let header = {};
  if (body) {
    header = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
    if (body instanceof FormData) {
      //
    } else {
      header['Content-Type'] = 'application/json';
    }
    return { method: method, headers: header, body: body };
  } else {
    header = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return { method: method, headers: header };
  }
}

const utils = {
  params: function (method, body) {
    let param = buildParams(method, body);
    return param;
  },

  buildTempShot: function (script, scene) {
    let tempShot = null;
    if (!scene.shot_list && script.features && script.features.toLowerCase() === 'basic') {
      tempShot = [
        {
          id: 0,
          scene_id: scene.id,
          shot_number: 1,
          original_shot_number: 1,
          shot_type1: null,
          selected_camera: 'A',
          VFX: 0,
          FX: 0,
          shot_description: '',
          notes: '',
          movement: [],
          camera: [],
          focus: [],
          frame: [],
          focal: [],
          subject: [],
          expanded: false,
        },
      ];
    } else if (scene.shot_list) {
      return scene.shot_list;
    }
    return tempShot;
  },
};

export default utils;
