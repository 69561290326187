// Libraries
import React, { useState, useContext, useEffect } from 'react';
import io from 'socket.io-client';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

// Components
import GeneralModal from '../components/Modals/GeneralModal.js';
import ProFlag from '../components/Elements/ProFlag.js';

//Services
import ScriptService from '../services/scriptService';
import ScriptWebSocket from '../services/scriptWebSocket';

// Set User Context
import { UserContext } from '../App';
import SessionContext from '../context/Session';

// Construct Component
const ScriptList = ({ script, onDeleteScript, creditsAvailable }) => {
  const { user } = useContext(UserContext);
  const { generalModalDetails, setGeneralModalDetails } = useContext(SessionContext);

  /*const [generalModalDetails, setGeneralModalDetails] = useState({
    open: false,
    header: '',
    message: '',
  });*/

  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear().toString().slice(-2);
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    const time = date.toLocaleString('en-US', options);

    return `${month}/${day}/${year} at ${time}`;
  };

  async function confirmedDeleteScript(scriptId) {
    setGeneralModalDetails({ open: false, header: null, message: null });
    let deleted = await ScriptService.deleteScript(scriptId);
    if (deleted) {
      onDeleteScript(scriptId);
    } else {
      console.error('Failed to delete script.');
      //TODO: Add Delete Script Failure condition
    }
  }

  const deleteScriptModal = (scriptId, scriptName) => {
    setGeneralModalDetails({
      open: true,
      header: (
        <span>
          <FontAwesomeIcon
            icon={faTrashCan}
            style={{ paddingRight: '20px', color: '#E35640', fontSize: '18' }}
          />
          Delete Script
        </span>
      ),
      message: (
        <div>
          Are you sure you would like to delete {scriptName}? This action cannot be undone and all
          scenes and shot data associated to the script will be lost forever.
          <br />
          <br />
          <div className="fullWidthContainer">
            <button
              className="buttonGray OneHalfFlex"
              onClick={() =>
                setGeneralModalDetails({
                  open: false,
                  header: null,
                  message: null,
                })
              }
            >
              Cancel
            </button>
            <button
              className="OneHalfFlex"
              style={{ backgroundColor: '#E35640', color: '#fff' }}
              onClick={() => confirmedDeleteScript(scriptId)}
            >
              Delete Script
            </button>
          </div>
        </div>
      ),
    });
  };

  const processPage = async (pageNumber) => {
    script.last_processed_page = pageNumber;
  };

  const addScene = (scene) => {
    script.scenes.push(scene);
  };

  const goToScript = (scriptId) => {
    window.location.href = `/app/ShotList/${scriptId}`;
  };

  return (
    <>
      {generalModalDetails && (
        <GeneralModal
          generalModalDetails={generalModalDetails}
          setModalIsOpen={setGeneralModalDetails}
        />
      )}
      {script.page_count !== script.last_processed_page && script.path ? (
        <ScriptWebSocket scriptId={script.id} processPage={processPage} addScene={addScene} />
      ) : null}
      <div className="OnePortionFlex">
        <div className="projectCard">
          <div className="FullWidthFlex projectCardHeader">
            <span className="ThreePortionFlex projectCardHeaderText">{script.script_name}</span>
            <span className="OnePortionFlex">
              <button
                onClick={() => goToScript(script.id)}
                className="buttonCloneLightPurple"
                style={{ marginTop: '-3px', float: 'right', minWidth: '120px' }}
              >
                View Shotlist
              </button>
            </span>
          </div>

          <div className="projectCardBody">
            <div className="FullWidthFlex">Created {formatCreatedAt(script.created_at)}</div>
            <div className="FullWidthFlex">
              {' '}
              {script.script_path && script.page_count !== script.last_processed_page ? (
                'Processing PDF File'
              ) : (
                <span>
                  <span style={{ paddingRight: '10px' }}>{script.scene_count} Scenes</span>|
                  <span style={{ paddingLeft: '10px' }}>{script.shot_count} Shots</span>
                </span>
              )}
            </div>
            <div className="FullWidthFlex" style={{ marginTop: '10px' }}>
              <span style={{ marginTop: '2px', paddingRight: '5px' }}>Auto generate:</span>
              <span style={{ marginTop: '2px' }}>
                {script && script.features ? (
                  <>
                    {script.features.toLowerCase() === 'pro' ||
                    script.features.toLowerCase() === 'pro freemium' ? (
                      <>On</>
                    ) : script.features.toLowerCase() === 'pro freemium10' ? (
                      <>10 Free Scenes</>
                    ) : script.features.toLowerCase() === 'basicplus' ? (
                      <>5 Free Scenes</>
                    ) : (
                      <>Off</>
                    )}
                  </>
                ) : (
                  <>Off</>
                )}
              </span>

              {!script || !script.features || script.features.toLowerCase() !== 'pro' ? (
                <span style={{ paddingLeft: '5px' }}>
                  <ProFlag
                    type="pro"
                    featureMsg="Upgrade this script to Pro"
                    scriptLevel={script && script.features ? script.features : 'basic'}
                    scriptId={script.id}
                  />
                </span>
              ) : null}

              {/*script &&
              script.features &&
              (script.features.toLowerCase() === 'pro' ||
                script.features.toLowerCase() === 'pro freemium') ? (
                <span style={{ marginTop: '2px' }}>On</span>
              ) : (
                <span>
                  {script && script.features && script.features.toLowerCase() === 'basicplus'
                    ? 'Free Trial: First 5 Scenes Only'
                    : 'Off'}
                  <span style={{ paddingLeft: '5px' }}>
                    <ProFlag
                      type="pro"
                      featureMsg="Upgrade this script to Pro"
                      scriptLevel={script && script.features ? script.features : 'basic'}
                      scriptId={script.id}
                    />
                  </span>
                </span>
              )*/}
            </div>
            <div className="FullWidthFlex">
              Project Type: {script.script_path ? 'PDF Script' : 'Copy/Paste Script'}
            </div>
          </div>

          <div className="FullWidthFlex projectCardFooter">
            <span className="OnePortionFlex">
              <img
                src="/icons/TrashIcon_Orange.png"
                className="Padded"
                alt="Trash Can Icon"
                onClick={() => {
                  deleteScriptModal(script.id, script.script_name);
                }}
                style={{
                  width: '25px',
                  float: 'right',
                  paddingLeft: '30px',
                  marginTop: '-10px',
                  marginBottom: '-10px',
                  cursor: 'pointer',
                }}
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScriptList;
