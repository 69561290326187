// Import Libraries
import React, { useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

// Import Components
import ExportProjectButton from './Project/exportProjectButton';

// Context
import ScriptContext from '../../context/Script';
import SessionContext from '../../context/Session';

// Construct Component
const ShotTableHeader = ({ view, setView }) => {
  const { script } = useContext(ScriptContext);
  const { isMobile } = useContext(SessionContext);

  function changePdfView() {
    setView({ ...view, pdfActive: !view.pdfActive });
  }

  useEffect(() => {
    if (isMobile) {
      setView({ ...view, viewColumns: 1 });
    }
  }, [isMobile]);

  return (
    <div className="shotHeaderBar">
      {isMobile && view.onScreen === 'shot' ? (
        <h3 onClick={() => setView({ ...view, onScreen: 'scene' })}>
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="linkText"
            style={{ paddingRight: '15px', paddingLeft: '0px' }}
          />{' '}
          BACK TO SCENES
        </h3>
      ) : (
        <h1 className="ShotList OnePortionFlex">{script ? script.script_name : ''}</h1>
      )}
      <div className={`OnePortionFlex ${view.isLoading ? 'hidden' : ''}`}>
        {script && script.page_count !== script.last_processed_page ? (
          <span className="progress-bar-text">
            <span>Still Processing:</span>
            <span className="progress-bar-container">
              <span
                className="progress-bar"
                style={{
                  width: `${
                    script && script.page_count !== script.last_processed_page
                      ? ((script.last_processed_page / script.page_count) * 100).toFixed(2)
                      : 0
                  }%`,
                }}
              ></span>
            </span>
          </span>
        ) : (
          <div className="FullWidthFlex" style={{ display: 'flex', alignItems: 'center' }}>
            {isMobile ? (
              <> </>
            ) : (
              <span className="TwoPortionFlex">
                <span
                  className={`pageIcon ${view.pdfActive == true ? 'pageIconSelected' : ''}`}
                  onClick={() => changePdfView()}
                  style={{ display: 'none' }}
                >
                  <FontAwesomeIcon icon={faFilePdf} style={{ padding: '5px 5px 5px 4px' }} />
                </span>

                <span
                  className={`pageIcon ${view.viewColumns == 1 ? 'pageIconSelected' : ''}`}
                  onClick={() => setView({ ...view, viewColumns: 1 })}
                >
                  <img
                    src="/icons/ListIcon_Black.png"
                    className="Padded SmallIcon"
                    alt="Card View Icon"
                  />
                  List
                </span>
                <span
                  className={`pageIcon ${view.viewColumns == 2 ? 'pageIconSelected' : ''}`}
                  onClick={() => setView({ ...view, viewColumns: 2 })}
                >
                  <img
                    src="/icons/CardIcon_Black.png"
                    className="Padded SmallIcon"
                    alt="List View Icon"
                  />
                  Cards
                </span>
              </span>
            )}
            <span className="OnePortionFlex">
              <ExportProjectButton />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShotTableHeader;
