// Import Libraries
import React, { useEffect, memo } from 'react';
import { useParams } from 'react-router-dom';

// Pages
import ShotList from './mainScriptPage';

// Context
import { ScriptProvider } from '../../context/Script';

// Construct Component
const ViewScriptDetails = memo(({ scriptId: propScriptId }) => {
  const { scriptId: paramScriptId } = useParams();
  const scriptId = propScriptId || paramScriptId;

  return (
    <ScriptProvider>
      <ShotList scriptId={scriptId} />
    </ScriptProvider>
  );
});

export default ViewScriptDetails;
