// Import Libraries
import React, { useState, useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

// Construct Component
const ShotTypeSelect = ({
  shot_types,
  typeNumber,
  sceneNumber,
  shot,
  handleShotFieldChange,
  id,
}) => {
  const [shotTypes, setShotTypes] = useState([]);
  const [options, setOptionValue] = useState([]);
  const [value, setValue] = useState({ label: '', value: '' });
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const fieldId = id || 'shotTypeSelect';
  const selectRef = useRef(null);

  useEffect(() => {
    let mapped_options = shot_types.map((item) => ({ label: item, value: item }));
    setOptionValue(mapped_options);

    if (shot[`shot_type${typeNumber}`] === null) {
      setValue({ label: '', value: '' });
    } else {
      setValue({ label: shot[`shot_type${typeNumber}`], value: shot[`shot_type${typeNumber}`] });
    }

    if (shot_types.length > 0) {
      setShotTypes(shot_types);
    }
  }, [shot_types, shot]);

  const handleChange = async (selectedOption) => {
    let update = await handleShotFieldChange(
      shot.id,
      `shot_type${typeNumber}`,
      selectedOption.value
    );
    shot[`shot_type${typeNumber}`] = selectedOption.value;
    setValue({ label: selectedOption.value, value: selectedOption.value });
  };

  const handleMouseClick = () => {
    if (selectRef.current) {
      selectRef.current.focus();
      setMenuIsOpen(true);
    }
  };

  const closeMenu = () => {
    //if (selectRef.current) {
    //selectRef.current.focus(false);
    setMenuIsOpen(false);
    //}
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon className="dropdownCaret" icon={faCaretDown} />
      </components.DropdownIndicator>
    );
  };

  return (
    <div
      className="focusLabelField"
      id={fieldId}
      onClick={handleMouseClick}
      onContextMenu={handleMouseClick}
      onMouseLeave={closeMenu}
    >
      <span className="focusLabel">
        <span className="focusLabelText">{`Type`}</span>
      </span>
      <Select
        ref={selectRef}
        key={`type${typeNumber}-${sceneNumber}.${shot.shot_number}`}
        //className="shotType"
        className="multiSelectDropdown"
        classNamePrefix="multiSelectDropdown"
        components={{ DropdownIndicator }}
        unstyled
        value={value}
        options={options}
        onChange={handleChange}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
      />
    </div>
  );
};

export default ShotTypeSelect;
