// Import Libraries
import React, { useState, useContext } from 'react';

// Import Components
import CreateableMultiSelectDropdown from '../../Elements/CreatableMultiSelectDropdown.js';
import RadioButton from '../../Elements/RadioButton.js';
import Checkbox from '../../Elements/Checkbox.js';
import ProFlag from '../../Elements/ProFlag.js';

// Services
import dictionaryService from '../../../services/dictionaryService.js';

// Context
import ScriptContext from '../../../context/Script';

function ExpandedForm({ scene, shot, dictionaries, handleShotFieldChange, addNewUserDictionary }) {
  const { script } = useContext(ScriptContext);
  const [tempNotes, setTempNotes] = useState({});

  const handleUpdateNotes = async (sceneNumber, shotId) => {
    if (tempNotes[shotId] === undefined) return;
    handleShotFieldChange(shotId, 'notes', tempNotes[shotId]);
    setTempNotes((prev) => ({
      ...prev,
      [shotId]: undefined,
    }));
    return;
  };

  return (
    <div
      className={`Padded ${shot.expanded ? '' : 'hidden'}`}
      style={{ borderTop: '1px dashed #7A64EB' }}
    >
      <div className="FullWidthFlex">
        <div className="OnePortionFlex Padded MinSmall">
          <div className="focusLabelField">
            <span className="focusLabel">
              <span className="focusLabelText">Notes</span>
            </span>
            <textarea
              className="inputWithLabel"
              value={tempNotes[shot.id] !== undefined ? tempNotes[shot.id] : shot.notes || ''}
              onChange={(e) =>
                setTempNotes((prev) => ({
                  ...prev,
                  [shot.id]: e.target.value,
                }))
              }
              onBlur={() => {
                handleUpdateNotes(scene.id, shot.id);
              }}
            />
          </div>
        </div>
      </div>

      <span className="FullWidthFlex">
        <span className="OnePortionFlex">
          <div className="FullWidthFlex">
            <div className="OnePortionFlex Padded MinSmall">
              <span>
                <CreateableMultiSelectDropdown
                  optionList={dictionaries['movement']}
                  selected={shot.movement}
                  baseItem={shot}
                  selectedFieldName={'movement'}
                  changeHandler={handleShotFieldChange}
                  addOption={addNewUserDictionary}
                  label={'Movement'}
                />
              </span>
            </div>
            <div className="OnePortionFlex Padded MinThird MinSmall">
              <span>
                <CreateableMultiSelectDropdown
                  optionList={dictionaries['camera']}
                  selected={shot.camera}
                  baseItem={shot}
                  selectedFieldName={'camera'}
                  changeHandler={handleShotFieldChange}
                  addOption={addNewUserDictionary}
                  label={'Camera'}
                />
              </span>
            </div>
          </div>
        </span>
        <span className="OnePortionFlex">
          <div className="FullWidthFlex">
            <div className="OnePortionFlex Padded MinSmall">
              <span>
                <CreateableMultiSelectDropdown
                  optionList={dictionaries['focus']}
                  selected={shot.focus}
                  baseItem={shot}
                  selectedFieldName={'focus'}
                  changeHandler={handleShotFieldChange}
                  addOption={addNewUserDictionary}
                  label={'Focus'}
                />
              </span>
            </div>
            <div className="OnePortionFlex Padded MinSmall">
              <span>
                <CreateableMultiSelectDropdown
                  optionList={dictionaries['frame']}
                  selected={shot.frame}
                  baseItem={shot}
                  selectedFieldName={'frame'}
                  changeHandler={handleShotFieldChange}
                  addOption={addNewUserDictionary}
                  label={'Frame'}
                />
              </span>
            </div>
          </div>
        </span>
      </span>

      <span className="FullWidthFlex">
        <span className="OnePortionFlex">
          <div className="FullWidthFlex">
            <div className="OnePortionFlex Padded MinSmall">
              <span>
                <CreateableMultiSelectDropdown
                  optionList={dictionaries['focal']}
                  selected={shot.focal}
                  baseItem={shot}
                  selectedFieldName={'focal'}
                  changeHandler={handleShotFieldChange}
                  addOption={addNewUserDictionary}
                  label={'Focal'}
                />
              </span>
            </div>
            <div className="OnePortionFlex Padded MinSmall"></div>
          </div>
        </span>
        <span className="OnePortionFlex"></span>
      </span>

      <span className="FullWidthFlex" style={{ padding: '5px' }}>
        <span className="OnePortionFlex Padded">
          <div className="FullWidthFlex popoverHover">
            <span
              className="OnePortionFlex label"
              style={{ paddingRight: '0px', minWidth: '80px', maxWidth: '80px' }}
            >
              <ProFlag
                type="pro"
                scriptLevel={script ? script.features : 'Basic'}
                scriptId={script.id}
              />
              Camera
            </span>
            <span className="TwoPortionFlex Padded">
              <div className="FullWidthFlex MinExSmall" style={{ alignItems: 'flex-end' }}>
                <span className="OnePortionFlex Padded" style={{ minWidth: '25px' }}>
                  <RadioButton
                    name={`selected_camera-${shot.id}`}
                    value="A"
                    label="A"
                    selected={shot.selected_camera === 'A' ? true : false}
                    saveUpdate={handleShotFieldChange}
                    isDisabled={
                      script.features && script.features.toLowerCase() !== 'basic' ? false : true
                    }
                  />
                </span>
                <span className="OnePortionFlex Padded" style={{ minWidth: '25px' }}>
                  <RadioButton
                    name={`selected_camera-${shot.id}`}
                    value="B"
                    label="B"
                    selected={shot.selected_camera === 'B' ? true : false}
                    saveUpdate={handleShotFieldChange}
                    isDisabled={
                      script.features && script.features.toLowerCase() !== 'basic' ? false : true
                    }
                  />
                </span>
                <span className="OnePortionFlex Padded" style={{ minWidth: '25px' }}>
                  <RadioButton
                    name={`selected_camera-${shot.id}`}
                    value="C"
                    label="C"
                    selected={shot.selected_camera === 'C' ? true : false}
                    saveUpdate={handleShotFieldChange}
                    isDisabled={
                      script.features && script.features.toLowerCase() !== 'basic' ? false : true
                    }
                  />
                </span>
                <span className="OnePortionFlex Padded" style={{ minWidth: '26px' }}>
                  <RadioButton
                    name={`selected_camera-${shot.id}`}
                    value="D"
                    label="D"
                    selected={shot.selected_camera === 'D' ? true : false}
                    saveUpdate={handleShotFieldChange}
                    isDisabled={
                      script.features && script.features.toLowerCase() !== 'basic' ? false : true
                    }
                  />
                </span>
              </div>
            </span>
          </div>
        </span>
        <span className="OnePortionFlex Padded">
          <div className="FullWidthFlex Padded">
            <div className="OnePortionFlex"></div>
            <div className="OnePortionFlex" style={{ marginLeft: '20px', minWidth: '45px' }}>
              <Checkbox
                name="FX"
                label="FX"
                selected={shot.FX}
                saveUpdate={handleShotFieldChange}
                spanClass="floatRight"
              />
            </div>
            <div className="OnePortionFlex" style={{ marginLeft: '20px', minWidth: '51px' }}>
              <Checkbox
                name="VFX"
                label="VFX"
                selected={shot.VFX}
                saveUpdate={handleShotFieldChange}
                spanClass="floatRight"
              />
            </div>
          </div>
        </span>
      </span>
    </div>
  );
}

export default ExpandedForm;
