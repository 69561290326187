// Import Libraries
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// Import Services
import ScriptService from '../../services/scriptService';

// Setup Modal to Root
Modal.setAppElement('#root');

// Construct Component
function ProcessingScriptModal({ modalIsOpen, setModalIsOpen, scriptId }) {
  const [error, setError] = useState(null);
  const [uhOhError, setUhOhError] = useState(null);

  function closeModal() {
    setError(null);
    setModalIsOpen(false);
    window.location.href = `/app/newProject/1`;
  }

  function goToManual() {
    window.location.href = '/app/scripts';
  }

  useEffect(() => {
    const setupPage = async () => {
      if (scriptId) {
        try {
          let setupScript = { page_count: 0, scenes: [] };
          let counter = 0;

          await new Promise((resolve) => setTimeout(resolve, 20000));
          setupScript = await ScriptService.getScriptById(scriptId);
          setupScript.scenes = await ScriptService.getAllScenes(scriptId);

          while (
            setupScript.scenes.length < 2 &&
            counter < 20 &&
            setupScript.page_count !== setupScript.last_processed_page
          ) {
            await new Promise((resolve) => setTimeout(resolve, 2000));
            setupScript = await ScriptService.getScriptById(scriptId);
            setupScript.scenes = await ScriptService.getAllScenes(scriptId);
            counter++;
          }

          if (setupScript.scenes.length < 1) {
            console.error('No scenes found');
            setUhOhError(true);
            return;
          }

          window.location.href = `/app/shotList/${scriptId}`;
        } catch (error) {
          console.error(error);
        }
      }
    };

    setupPage();
  }, [scriptId]);

  return (
    <div>
      <Modal isOpen={modalIsOpen} contentLabel="Upload Script" className={`modal`}>
        {!uhOhError ? (
          <div className="DeepPadded" style={{ textAlign: 'center' }}>
            <div className="fullWidthContainer">
              <img
                style={{ width: '50%', minWidth: '50px', margin: '0 auto' }}
                src="/Spinner-2.9s-297px.gif"
                className="smallLoadingGif"
                alt="Loading.."
              />
            </div>
            <div className="large bold">Processing your script</div>
            <div className="subHeader Padded">
              We are breaking it down into scenes. This could take a few minutes. Be right back with
              your shots!
              <br />
            </div>
          </div>
        ) : (
          <>
            <div className="close-button" role="button" onClick={() => closeModal()}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="sizeUp">
              <div className="fullWidthContainer modalHeader">O-oh!</div>
              <div className="modalContent">
                <div className="fullWidthContainer">
                  Something went wrong. Please try again.
                  <br />
                  <br />
                </div>
                <div>
                  Our software works best with standard US script formatting for narrative projects.
                  We may be able to help with a commercial script or a foreign language project as
                  well. Send us your script via the{' '}
                  <a href="https://www.shotlistai.com/help" target="_blank">
                    Contact Us
                  </a>{' '}
                  form and we'll do our best.
                  <br />
                  <br />
                  Don't have a standard US format PDF or is the script in another language?{' '}
                  <a className="purpleText link" onClick={() => goToManual()}>
                    Manually Enter Script
                  </a>
                </div>

                <div className="fullWidthContainer">
                  <span className="OnePortionFlex"></span>
                  <span className="OnePortionFlex">
                    <span className="OnePortionFlex">&nbsp;</span>
                    <button
                      style={{ width: '100%' }}
                      className={`OnePortionFlex buttonClone`}
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      Try Again
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
        {error && <p className="errorText">{error}</p>}
      </Modal>
    </div>
  );
}

export default ProcessingScriptModal;
