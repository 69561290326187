import { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';

// Import Services
import ScriptService from '../services/scriptService';
import Utils from '../services/utilities';

function ScriptWebSocket({ scriptId, user, script, setScript }) {
  const [isConnected, setIsConnected] = useState(false);
  const socketRef = useRef(null);
  const scriptRef = useRef(script);
  const baseUrl = process.env.REACT_APP_API_URL.replace('/api', '');

  useEffect(() => {
    scriptRef.current = script;
  }, [script]);

  const updateScene = (processScene) => {
    setScript((existingScript) => {
      if (existingScript && existingScript.scenes && processScene) {
        const updatedScenes = existingScript.scenes.map((scene) => {
          if (scene.id === processScene.id) {
            return {
              ...scene,
              ['ai_status']: processScene.ai_status,
              ['isGeneratingShots']: processScene.isGeneratingShots,
              ['generation_error']: processScene.generation_error,
              ['shot_list']: processScene.shot_list,
              ['portions']: processScene.portions,
            };
          }
          return scene;
        });
        return {
          ...existingScript,
          scenes: updatedScenes,
        };
      } else {
        console.error('Script or script.scenes is undefined.');
        return existingScript;
      }
    });
  };

  const addScene = async (newScene) => {
    setScript((prevScript) => {
      const foundScene = prevScript.scenes.find((scene) => scene.id === newScene.id);
      if (foundScene) {
        return prevScript;
      }

      //newScene.portions = ScriptService.calculatePortion(newScene);
      newScene.shot_list = Utils.buildTempShot(prevScript, newScene);
      const updatedScenes = [...prevScript.scenes, newScene].sort(
        (a, b) => a.scene_position - b.scene_position
      );

      return { ...prevScript, scenes: updatedScenes };
    });
  };

  useEffect(() => {
    const newSocket = io.connect(baseUrl, { transports: ['websocket'] });
    socketRef.current = newSocket;

    newSocket.on('connect', () => {
      setIsConnected(true);
      newSocket.emit('joinRoom', scriptId);
    });

    newSocket.on('disconnect', (reason) => {
      console.error('WebSocket disconnected:', reason);
      setIsConnected(false);
    });

    newSocket.on('pageProcessed', (pageNumber) => {
      setScript((prevScript) => ({
        ...prevScript,
        last_processed_page: pageNumber,
      }));
    });

    newSocket.on('updateScript', (scriptId, field, value) => {
      setScript((prevScript) => ({
        ...prevScript,
        [field]: value,
      }));
    });

    newSocket.on('addScene', (scene) => {
      addScene(scene);
    });

    newSocket.on('updateScene', updateScene);

    newSocket.on('updateSceneManual', (value) => {
      if (value.field === 'portions') {
        setScript((prevScript) => {
          const updatedScenes = prevScript.scenes.map((scene) => {
            if (scene.id === value.sceneId) {
              return {
                ...scene,
                portions: value.portions,
              };
            }
            return scene;
          });

          return {
            ...prevScript,
            scenes: updatedScenes,
          };
        });
      }
    });

    return () => {
      newSocket.disconnect();
    };
  }, [scriptId]);

  return null;
}

export default ScriptWebSocket;
