// Import Libraries
import React, { createContext, useEffect, useState, useMemo } from 'react';

// Create a context
const SessionContext = createContext();

// Create a context provider component
export const SessionProvider = ({ children }) => {
  const [generalModalDetails, setGeneralModalDetails] = useState({
    open: false,
    header: '',
    message: '',
    wide: false,
  });
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  // Memoize the context value
  const contextValue = useMemo(
    () => ({
      generalModalDetails,
      setGeneralModalDetails,
      isMobile,
    }),
    [generalModalDetails, isMobile]
  );

  return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>;
};

export default SessionContext;
