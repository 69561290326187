// Import Libraries
import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons';

// Import Components
import RadioButton from '../Elements/RadioButton.js';
import ProFlag from '../Elements/ProFlag.js';

// Import Utilties
import scriptService from '../../services/scriptService';
import UserService from '../../services/userService';

// Context
import { UserContext } from '../../App.js';
import ScriptContext from '../../context/Script';

// Setup Modal to Root
Modal.setAppElement('#root');

// Construct Component
function ScriptExport({ modalIsOpen, setModalIsOpen }) {
  const { user } = useContext(UserContext);
  const { script } = useContext(ScriptContext);
  const [errorMsg, setErrorMsg] = useState('');
  const [checkedScenes, setCheckedScenes] = useState({});
  const [isExportDisabled, setIsExportDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [exportOptions, setExportOptions] = useState({
    description: true,
    allFields: true,
    imageSize: 'None',
    format: 'PDF',
  });

  const handleCheckboxChange = (sceneId) => {
    setCheckedScenes((prevState) => ({
      ...prevState,
      [sceneId]: !prevState[sceneId],
    }));
  };

  async function exportScript() {
    let scenesToExport = await getSceneList();
    if (scenesToExport.length > 0) {
      setIsLoading(true);
      let logId = await UserService.addActionLog(
        'Export Script',
        script.id,
        'script',
        scenesToExport
      );
      let exportedScript = await scriptService.getScriptExport(
        script.id,
        scenesToExport,
        exportOptions
      );
      UserService.completeActionLog(logId);
      setIsLoading(false);
    }
  }

  async function getSceneList() {
    let scenesToExport = [];
    for (const [key, value] of Object.entries(checkedScenes)) {
      if (value) {
        scenesToExport.push(key);
      }
    }
    if (scenesToExport.length === 0) {
      setErrorMsg('You must select at least one scene to export.');
      return [];
    } else {
      return scenesToExport;
    }
  }

  function selectAll() {
    let newCheckedScenes = {};
    script.scenes.forEach((scene) => {
      newCheckedScenes[scene.id] = true;
    });
    setCheckedScenes(newCheckedScenes);
  }

  function selectNone() {
    let newCheckedScenes = {};
    script.scenes.forEach((scene) => {
      newCheckedScenes[scene.id] = false;
    });
    setCheckedScenes(newCheckedScenes);
  }

  function selectWithShots() {
    let newCheckedScenes = {};
    script.scenes.forEach((scene) => {
      if (
        scene.shot_list &&
        scene.shot_list[0] &&
        (scene.shot_list.length > 1 || scene.shot_list[0].id !== 0)
      ) {
        newCheckedScenes[scene.id] = true;
      } else {
        newCheckedScenes[scene.id] = false;
      }
    });
    setCheckedScenes(newCheckedScenes);
  }

  const toggleExportOptions = (field) => {
    setExportOptions((prevExportOptions) => ({
      ...prevExportOptions,
      [field]: !prevExportOptions[field],
    }));
  };

  // Define the handleImageSizeChange function
  const handleImageSizeChange = (holdA, holdB, size) => {
    // Update the exportOptions state to reflect the selected image size
    setExportOptions((prevOptions) => ({
      ...prevOptions,
      imageSize: size,
    }));
  };

  const changeFormat = (hold, field, newFormat) => {
    if (user && user.level.toLowerCase() === 'pro') {
      setExportOptions((prevExportOptions) => ({
        ...prevExportOptions,
        ['format']: newFormat,
      }));
    }
  };

  useEffect(() => {
    selectWithShots();
  }, [script]);

  useEffect(() => {
    async function checkExport() {
      let scenesToExport = await getSceneList();
      if (scenesToExport.length > 0) {
        setErrorMsg('');
        setIsExportDisabled(false);
      } else {
        setErrorMsg('You must select at least one scene to export.');
        setIsExportDisabled(true);
      }
    }
    checkExport();
  }, [checkedScenes]);

  return (
    <div>
      <Modal isOpen={modalIsOpen} contentLabel="Upload Script" className="modal wide">
        <button className="close-button" onClick={() => setModalIsOpen(false)}>
          <FontAwesomeIcon icon={faClose} />
        </button>
        {isLoading ? (
          <>
            <div className="modalHeader">
              <div className="bold">We'll be right back with that export for you.</div>
            </div>
            <div className="modalContent">
              <div className="fullWidthContainer">
                <img
                  style={{ width: '300px', maxWidth: '300px', margin: '0 auto' }}
                  src="/Spinner-2.9s-297px.gif"
                  className="smallLoadingGif"
                  alt="Loading.."
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="modalHeader">
              <div className="bold">Export your script</div>
            </div>

            <div className="modalContent">
              <div className="FullWidthFlex card Padded">
                <span className="label">Include</span>
                <span className="OnePortionFlex MinSmall Padded">
                  <span> Description</span>
                  <span>
                    <span
                      className="linkText"
                      onClick={() => toggleExportOptions('description')}
                      style={{ cursor: 'pointer' }}
                    >
                      {exportOptions.description ? (
                        <FontAwesomeIcon icon={faToggleOn} />
                      ) : (
                        <FontAwesomeIcon icon={faToggleOff} />
                      )}
                    </span>
                  </span>
                </span>
                <span className="OnePortionFlex MinSmall Padded">
                  <span>All Fields</span>
                  <span>
                    <span
                      className="linkText"
                      onClick={() => toggleExportOptions('allFields')}
                      style={{ cursor: 'pointer' }}
                    >
                      {exportOptions.allFields ? (
                        <FontAwesomeIcon icon={faToggleOn} />
                      ) : (
                        <FontAwesomeIcon icon={faToggleOff} />
                      )}
                    </span>
                  </span>
                </span>

                <span className="OnePortionFlex MinSmall Padded popoverHover">
                  <span style={{ float: 'right' }}>
                    <span className="label">
                      Format
                      <ProFlag type="user" scriptLevel={script.features} script={script.id} />
                    </span>
                    <span>
                      <span className="Padded">
                        <RadioButton
                          name="format"
                          value="PDF"
                          label=" PDF"
                          selected={exportOptions.format === 'PDF' ? true : false}
                          saveUpdate={changeFormat}
                          isDisabled={
                            user.level && user.level.toLowerCase() !== 'pro' ? true : false
                          }
                        />
                      </span>
                      <span className="Padded">
                        <RadioButton
                          name="format"
                          value="CSV"
                          label=" CSV"
                          selected={exportOptions.format === 'CSV' ? true : false}
                          saveUpdate={changeFormat}
                          isDisabled={
                            user.level && user.level.toLowerCase() !== 'pro' ? true : false
                          }
                        />
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div></div>
              <div className="OnePortionFlex MinSmall Padded">
                <span className="label">Images</span>
                <span>
                  <span className="Padded">
                    <RadioButton
                      label="None"
                      name="imageSize"
                      value="None"
                      selected={exportOptions.imageSize === 'None' ? true : false}
                      saveUpdate={handleImageSizeChange}
                    />
                  </span>
                  <span className="Padded">
                    <RadioButton
                      label="Small"
                      name="imageSize"
                      value="Small"
                      selected={exportOptions.imageSize === 'Small' ? true : false}
                      saveUpdate={handleImageSizeChange}
                    />
                  </span>
                  <span className="Padded">
                    <RadioButton
                      label="Medium"
                      name="imageSize"
                      value="Medium"
                      selected={exportOptions.imageSize === 'Medium' ? true : false}
                      saveUpdate={handleImageSizeChange}
                    />
                  </span>
                  <span className="Padded">
                    <RadioButton
                      label="Large"
                      name="imageSize"
                      value="Large"
                      selected={exportOptions.imageSize === 'Large' ? true : false}
                      saveUpdate={handleImageSizeChange}
                    />
                  </span>
                </span>
              </div>

              <div style={{ paddingTop: '20px', paddingBottom: '10px' }}>
                Which scenes would you like included in your export?
              </div>
              <div className="FullWidthFlex">
                <button
                  style={{ marginBottom: '20px' }}
                  className="OnePortionFlex MinExSmall"
                  onClick={() => selectAll()}
                >
                  Select All
                </button>
                <button
                  style={{ marginBottom: '20px' }}
                  className="OnePortionFlex MinExSmall"
                  onClick={() => selectNone()}
                >
                  Select None
                </button>
                <button
                  style={{ marginBottom: '20px' }}
                  className="TwoPortionFlex MinExSmall"
                  onClick={() => selectWithShots()}
                >
                  Select With Shots
                </button>
                <span className="OnePortionFlex exportModalSpacing">&nbsp;</span>
                <button
                  style={{ float: 'right', marginBottom: '20px' }}
                  className="OnePortionFlex buttonClone MinSmall"
                  disabled={isExportDisabled}
                  onClick={() => exportScript()}
                >
                  Export Shotlist
                </button>
              </div>
            </div>
            <div>
              {script.scenes?.map((scene) => (
                <div className="card FullWidthFlex" key={scene.id} style={{ padding: '10px' }}>
                  <div
                    className="OnePortionFlex cardTop Left"
                    onClick={() => handleCheckboxChange(scene.id)}
                  >
                    {checkedScenes[scene.id] ? (
                      <FontAwesomeIcon className="linkText" icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon className="linkText" icon={faSquare} />
                    )}
                  </div>
                  <div className="OnePortionFlex cardTop">{scene.scene_number}</div>
                  <div className="TenPortionFlex">{scene.header_text}</div>
                  <div className="TwoPortionFlex">
                    {scene.shot_list &&
                    (scene.shot_list.length > 1 ||
                      (scene.shot_list[0] && scene.shot_list[0].id !== 0)) ? (
                      <span className="purpleText bold">{scene.shot_list.length} Shots</span>
                    ) : (
                      <span>0 Shots</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="FullWidthFlex errorText" style={{ paddingTop: '30px' }}>
              {errorMsg}
            </div>
            <div className="FullWidthFlex" style={{ paddingTop: '30px' }}>
              <button
                className="OnePortionFlex MinExSmall button"
                onClick={() => setModalIsOpen(false)}
              >
                Cancel
              </button>
              <span className="OnePortionFlex MinExSmall"></span>
              <span className="OnePortionFlex MinExSmall"></span>
              <span className="TwoPortionFlex MinSmall"></span>
              <button
                className="TwoPortionFlex buttonClone"
                disabled={isExportDisabled}
                onClick={() => exportScript()}
              >
                Export Shotlist
              </button>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
}

export default ScriptExport;
