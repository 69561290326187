// Import Libraries
import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faExclamation } from '@fortawesome/free-solid-svg-icons';

// Get Context
import SessionContext from '../../context/Session';

// Build Component
function FreeTrialActivated() {
  const { isMobile } = useContext(SessionContext);
  const [warningClosed, setWarningClosed] = useState(false);

  const goToUpload = async () => {
    window.location.href = `/app/newProject/1`;
  };

  return (
    <>
      {isMobile && !warningClosed ? (
        <>
          <div className="ReactModal__Overlay"></div>
          <div className="modal modalMobile centered">
            <img
              src="/icons/WarningIcon_Purple.png"
              className="Padded"
              alt="Thumbs Up Icon"
              style={{ width: '10%' }}
            />
            <div className="bold Padded purpleText">Switch to desktop</div>
            <div className="Padded">
              Not yet optimized for mobile. We highly recommend you switch to a tablet or computer.
            </div>
            <div className="Padded">
              <button className="buttonGray fullWidth" onClick={() => setWarningClosed(true)}>
                Continue on Mobile
              </button>
            </div>
          </div>
        </>
      ) : null}
      <div className="fullWidthContainer">
        <div className="OnePortionFlex Padded"></div>
        <div className="FivePortionFlex Padded">
          <div className="centered">
            <div className="bold large Padded">
              <img
                src="/icons/ThumbsUpIcon.png"
                className="Padded"
                alt="Thumbs Up Icon"
                style={{ width: '30%' }}
              />
            </div>
            <div className="bold large Padded purpleText">Congratulations!</div>
            <div className="Padded subHeader">You have successfully activated your free trial.</div>
          </div>

          <div style={{ paddingTop: '30px' }}>
            <div className="Padded">Your free trial includes AI-generated shotlists:</div>
            <ul style={{ textAlign: 'left', paddingBottom: '20px' }}>
              <li style={{ marginBottom: '10px' }}>For 10 scenes of your first project</li>
              <li style={{ marginBottom: '10px' }}>For 5 scenes of your second project</li>
              <li style={{ marginBottom: '10px' }}>
                Free use of most of our pro features for those projects.
              </li>
            </ul>
            <button className="buttonClone fullWidth" onClick={() => goToUpload()}>
              Get Started
            </button>
          </div>
        </div>
        <div className="OnePortionFlex Padded"></div>
      </div>
    </>
  );
}

export default FreeTrialActivated;
