// Import Libraries
import React, { useState } from 'react';

function ControlBox({ scene, shot, deleteHandler, addShot, expandContractShot, view }) {
  return (
    <div
      className={`${
        view.viewColumns === 1
          ? 'fullWidthContainer optionListHorizontal'
          : 'OnePortionFlex optionList'
      }`}
    >
      <div
        style={{ padding: '5px', textAlign: 'center' }}
        className={`${view.viewColumns === 1 ? 'OnePortionFlex' : null} linkText`}
        onClick={() => {
          deleteHandler(scene.id, shot.id);
        }}
      >
        <img
          src="/icons/TrashIcon_Orange.png"
          className="Padded"
          alt="Trash Can Icon"
          style={{ width: '20px', marginBottom: '-10px' }}
        />
      </div>
      <div
        style={{ padding: '5px', textAlign: 'center' }}
        className={`${view.viewColumns === 1 ? 'OnePortionFlex' : null} linkText`}
        onClick={() => {
          expandContractShot(shot);
        }}
      >
        {shot.expanded ? (
          <img
            src="/icons/ContractIcon_Black.png"
            className="Padded"
            alt="Contract Icon"
            style={{ width: '20px', marginBottom: '-10px' }}
          />
        ) : (
          <img
            src="/icons/ExpandIcon_Black.png"
            className="Padded"
            alt="Expand Icon"
            style={{ width: '20px', marginBottom: '-10px' }}
          />
        )}
      </div>
      <div
        style={{ padding: '5px', textAlign: 'center' }}
        className={`${view.viewColumns === 1 ? 'OnePortionFlex' : null} linkText`}
        onClick={() => {
          addShot(shot.id);
        }}
      >
        <img
          src="/icons/AddIcon_Black.png"
          className="Padded"
          alt="Add Icon"
          style={{ width: '20px', marginBottom: '-10px' }}
        />
      </div>
    </div>
  );
}

export default ControlBox;
