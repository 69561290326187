// Import Libraries
import React, { useState } from 'react';
import Modal from 'react-modal';

// Import Components
import GeneralModal from '../components/Modals/GeneralModal.js';

// Import Content
//import privacyPolicy from '../content/privacyPolicy.html';

Modal.setAppElement('#root');

// Construct Component
function Footer() {
  const [generalModalDetails, setGeneralModalDetails] = useState({
    open: false,
    header: '',
    message: '',
  });

  const openPrivacyPolicy = async () => {
    let policy = await fetch('../privacyPolicy.html');
    if (policy.ok) {
      let policyText = (await policy.text()).toString();
      setGeneralModalDetails({
        open: true,
        header: '',
        message: <div dangerouslySetInnerHTML={{ __html: policyText }} />,
      });
    }
  };

  const openTerms = async () => {
    let terms = await fetch('../termsofUse.html');
    if (terms.ok) {
      let termsText = (await terms.text()).toString();
      setGeneralModalDetails({
        open: true,
        header: '',
        message: <div dangerouslySetInnerHTML={{ __html: termsText }} />,
      });
    }
  };
  return (
    <footer className="footer">
      <GeneralModal
        generalModalDetails={generalModalDetails}
        setModalIsOpen={setGeneralModalDetails}
      />
      <div className="fullWidthContainer">
        <span className="OnePortionFlex">
          <div>
            <a
              style={{ color: 'white', textDecoration: 'none' }}
              href="https://www.shotlistai.com/faq"
              target="_blank"
              rel="noopener noreferrer"
            >
              FAQ
            </a>
          </div>
          <div>
            <a
              style={{ color: 'white', textDecoration: 'none' }}
              href="https://www.shotlistai.com/help"
              target="_blank"
              rel="noopener noreferrer"
            >
              Support / Contact Us
            </a>
          </div>
        </span>
        <span className="OnePortionFlex centered">Copyright 2023</span>
        <span className="OnePortionFlex centered">
          <div style={{ cursor: 'pointer' }} onClick={() => openPrivacyPolicy()}>
            Privacy Policy
          </div>
          <div style={{ cursor: 'pointer' }} onClick={() => openTerms()}>
            Terms of Use
          </div>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
