import userService from './userService';

function headers(body) {
  if (body) {
    return {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    };
  } else {
    return { Authorization: `Bearer ${localStorage.getItem('token')}` };
  }
}

function getHeader() {
  return {
    method: 'GET',
    headers: headers(),
  };
}

function deleteHeader() {
  return {
    method: 'DELETE',
    headers: headers(),
  };
}
function postHeader(body) {
  let response = {
    method: 'POST',
    headers: headers(body),
  };
  if (body) {
    response.body = body;
  }
  return response;
}

const scriptService = {
  /**
   * Retrieves a list of scripts for the logged in user.
   *
   * @returns {Promise<Array>} A promise that resolves to an array of script objects
   * representing the user's scripts if the request is successful,
   * or an empty array if the request fails or encounters an error.
   */
  getScripts: async function () {
    try {
      let url = `${process.env.REACT_APP_API_URL}/scripts/userScripts`;
      const scriptResponse = await fetch(url, getHeader());

      if (scriptResponse.ok) {
        const scripts = await scriptResponse.json();
        return scripts;
      } else {
        return [];
      }
    } catch (err) {
      console.error(err);
    }
  },

  /**
   * Retrieves a script by its unique id
   *
   * @param {string} id - The unique id of the script to retrieve.
   * @returns {Promise<any>} - A promise that resolves with the script data if successful, or rejects with an error.
   *
   * @throws {Error} If the request to retrieve the script fails or encounters an error.
   */
  getScriptById: async function (id) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/scripts/script/${id}`;
      let header = getHeader();
      const getScript = await fetch(url, header);
      if (getScript.status === 404) {
        return {};
      }

      if (getScript.ok) {
        const scriptData = await getScript.json();
        return scriptData;
      } else {
        throw new Error(
          `Failed to retrieve script with status ${getScript.status}: ${getScript.statusText}`
        );
      }
    } catch (error) {
      console.error(error);
      throw new Error(`Script retrieval failed: ${error.message}`);
    }
  },

  /**
   * Retrieves scenes and shots for a script by the script id.
   *
   * @param {string} id - The id of the script for which scenes with shots are retrieved.
   * @returns {Promise<any[]>} - A promise that resolves with an array of scenes if successful.
   *
   * @throws {Error} If the request to retrieve scenes with shots fails or encounters an error.
   */
  getAllScenes: async function (id) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/scripts/scenesWithShots/${id}`;
      const sceneResponse = await fetch(url, getHeader());

      if (sceneResponse.ok) {
        const scenes = await sceneResponse.json();
        return scenes;
      } else {
        throw new Error(
          `Failed to retrieve scenes with shots with status ${sceneResponse.status}: ${sceneResponse.statusText}`
        );
      }
    } catch (error) {
      throw new Error(`Scene retrieval failed: ${error.message}`);
    }
  },

  /**
   * Makes an asynchronous API call to the backend to retrieve a list of shots for a given scene.
   *
   * @param {string} scriptId - The ID of the script the scene relates to.
   * @param {string} sceneId - The ID of the scene for which to retrieve the shot list.
   * @returns {Promise<Response>} - A Promise that resolves with the API response containing the shot list if successful, or rejects with an error if the API call fails.
   * @throws {Error} - Throws an error if the API call fails or encounters an exception.
   */
  getShotListForScene: async function (scriptId, sceneId) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/scripts/getShotListForScene`;
      let body = JSON.stringify({ sceneId: sceneId, scriptId: scriptId });
      const response = await fetch(url, postHeader(body));
      if (response.ok) {
        let shotList = await response.json();
        return shotList;
      } else {
        throw new Error(
          `Failed to retrieve shot list with status ${response.status}: ${response.statusText}`
        );
      }
    } catch (error) {
      console.error(error);
      throw new Error(`Shot list retrieval failed: ${error.message}`);
    }
  },

  /**
   * Retrieves a PDF file that can be used to display the PDF
   *
   * @param {string} pdfPath - The path to the PDF file to be retrieved.
   * @returns {Promise<string>} A Promise that resolves to a PDF.
   * @throws {Error} If the PDF retrieval fails, an Error with an error message is thrown.
   */
  getPdf: async function (pdfPath) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/scripts/getPdf?path=${encodeURIComponent(
        pdfPath
      )}`;
      const response = await fetch(url, getHeader());
      const pdfData = await response.arrayBuffer();
      const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
      const pdf = URL.createObjectURL(pdfBlob);
      return pdf;
    } catch (error) {
      throw new Error(`PDF retrieval failed: ${error.message}`);
    }
  },

  /**
   * Retrieves a PDF file of the script export
   *
   * @param {string} scriptId - The id of the script to export
   * @param {string} scenes - Optional: scene Ids you want to export (if limited)
   * @param {string} exportOptions - Optional: export options for the script
   * @returns {Promise<string>} A Promise that resolves to a PDF.
   * @throws {Error} If the PDF export retrieval fails, an Error with an error message is thrown.
   */
  getScriptExport: async function (scriptId, scenes, exportOptions) {
    try {
      if (!scenes) {
        scenes = [];
      }
      const request = `${process.env.REACT_APP_API_URL}/reports/customers/getScriptExport`;
      let requestBody = JSON.stringify({
        scriptId: scriptId,
        scenes: scenes,
        exportOptions: exportOptions,
      });

      const response = await fetch(request, postHeader(requestBody), requestBody);

      if (!response.ok) {
        throw new Error(`Failed to retrieve PDF: ${response.status} ${response.statusText}`);
      }

      // Create a temporary anchor element for downloading
      const a = document.createElement('a');
      a.style.display = 'none';
      document.body.appendChild(a);

      let fileName = '';
      if (exportOptions && exportOptions.format === 'CSV') {
        fileName = `script-export-${scriptId}.csv`;
      } else {
        fileName = `script-export-${scriptId}.pdf`;
      }

      // Read and download the response in chunks
      const reader = response.body.getReader();
      const downloadChunks = [];
      let totalBytes = 0;

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        downloadChunks.push(value);
        totalBytes += value.length;
      }

      const blob = new Blob(downloadChunks, { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(blob);

      a.href = pdfUrl;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(pdfUrl);
      document.body.removeChild(a);

      return true;
    } catch (error) {
      throw new Error(`PDF retrieval and download failed: ${error.message}`);
    }
  },

  /**
   * Deletes a script by sending a DELETE request to the backend.
   *
   * @param {string} id - The unique identifier of the script to delete.
   * @returns {Promise<boolean>} - A promise that resolves with a boolean indicating success or failure of the delete operation.
   *
   * @throws {Error} If the script deletion request fails or encounters an error.
   */
  deleteScript: async function (id) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/scripts/${id}`;
      const deleteScriptResponse = await fetch(url, deleteHeader());
      if (deleteScriptResponse.ok) {
        return true;
      }
      return false;
    } catch (error) {
      throw new Error(`Script deletion failed: ${error.message}`);
    }
  },

  /**
   * Checks the availability of a script name - cannot have duplicate non-deleted script names.
   *
   * @param {string} name - The script name to be validated.
   * @returns {boolean} - Returns true if the script name is available, otherwise false.
   *
   * @throws {Error} If the script name validation request fails or encounters an error.
   */
  checkScriptName: async function (name) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/scripts/validate/scriptName/${name}`;
      const checkScript = await fetch(url, getHeader());
      return checkScript;
    } catch (error) {
      throw new Error(`Script name validation failed: ${error.message}`);
    }
  },

  /**
   * Uploads a script PDF file to the server for a specific user.
   *
   * @param {string} id - The user's ID.
   * @param {string} scriptName - The name of the script being uploaded.
   * @param {File} selectedFile - The PDF file to upload.
   * @returns {Promise<{ success: boolean, data: any }>} - A promise that resolves with an object containing the upload result.
   *
   * @throws {Error} If the upload request fails or encounters an error.
   */
  uploadScript: async function (id, scriptName, selectedFile) {
    try {
      const formData = new FormData();
      formData.append('userId', id);
      formData.append('scriptName', scriptName);
      formData.append('pdfFile', selectedFile);
      let header = postHeader();
      header.body = formData;

      let url = `${process.env.REACT_APP_API_URL}/scripts/upload`;
      const uploadResponse = await fetch(url, header);
      const uploadData = await uploadResponse.json();
      if (uploadResponse.ok) {
        return { success: true, data: uploadData };
      } else {
        return { success: false, data: uploadData.message };
      }
    } catch (error) {
      throw new Error(`Upload failed: ${error.message}`);
    }
  },

  /**
   * Creates a manual script for a specified user.
   *
   * @param {string} userId - The ID of the user for whom the manual script is created.
   * @param {string} scriptName - The name of the manual script to be created.
   * @returns {Promise<Response>} The ID of the script if it was created successfully.
   * @throws {Error} If the API call fails or encounters an error, it will throw an error with a corresponding message.
   */
  createManualScript: async function (userId, scriptName) {
    try {
      const body = JSON.stringify({
        userId: userId,
        scriptName: scriptName,
      });
      let url = `${process.env.REACT_APP_API_URL}/scripts/manualScript`;
      const response = await fetch(url, postHeader(body));

      if (response.ok) {
        let scriptData = await response.json();
        return scriptData;
      } else {
        throw new Error(`Could not create a manual script. Please try again or contact us.`);
      }
    } catch (error) {
      throw new Error(`Shot change failed: ${error.message}`);
    }
  },

  /**
   * Creates a manual script for a specified user.
   *
   * @param {string} scriptId - The ID of the script to add a scene
   * @param {string} position - The position of the scene to add.
   * @returns {Promise<Response>} The ID of the script if it was created successfully.
   * @throws {Error} If the API call fails or encounters an error, it will throw an error with a corresponding message.
   */
  createManualScene: async function (scriptId, position) {
    try {
      const body = JSON.stringify({
        scriptId: scriptId,
        position: position,
      });
      let url = `${process.env.REACT_APP_API_URL}/scripts/addScene`;
      const response = await fetch(url, postHeader(body));

      if (response.ok) {
        let sceneData = await response.json();
        return sceneData;
      } else {
        throw new Error(`Could not create a scene. Please try again or contact us.`);
      }
    } catch (error) {
      throw new Error(`Scene creation failed: ${error.message}`);
    }
  },

  /**
   * Deletes a scene from a script
   *
   * @param {string} sceneId - The ID of the scene to delete
   * @returns {Promise<Response>} The ID of the script if it was created successfully.
   * @throws {Error} If the API call fails or encounters an error, it will throw an error with a corresponding message.
   */
  deleteScene: async function (sceneId) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/scripts/deleteScene/${sceneId}`;
      const response = await fetch(url, deleteHeader());

      if (response.ok) {
        let sceneData = await response.json();
        return sceneData;
      } else {
        throw new Error(`Could not delete a scene. Please try again or contact us.`);
      }
    } catch (error) {
      throw new Error(`Scene deletion failed: ${error.message}`);
    }
  },

  /**
   * Generates a scene list for a script by sending a POST request to the backend. Does not wait for response
   *
   * @param {string} scriptId - The unique identifier of the script for which the scene list is generated.
   * @param {string} filePath - The file path or location where the scene list should be generated.
   * @returns {void} - This function does not return a value, but it sends a request to generate the scene list.
   *
   * @throws {Error} If the request to generate the scene list fails or encounters an error.
   */
  generateSceneList: function (scriptId, filePath) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/scripts/generateSceneList`;
      let requestBody = JSON.stringify({
        scriptId: scriptId,
        filePath: filePath,
      });

      fetch(url, postHeader(requestBody), requestBody);
    } catch (error) {
      console.error('error generating scene list');
      console.error(error);
      throw new Error(`Scene list generation failed: ${error.message}`);
    }
  },

  /**
   * Updates scene information in the database.
   *
   * @async
   * @param {number|string} sceneId - The unique identifier for the scene to be updated.
   * @param {string} field - The specific field within the scene that needs to be updated.
   * @param {string|number|boolean} newValue - The new value to update the field to.
   * @param {number|string} scriptId - The identifier of the script that contains the scene.
   * @returns {Promise<Response>} A promise that resolves to the response object from the fetch call if the update is successful.
   * @throws {Error} Throws an error if the API call is unsuccessful or if any other error occurs during execution.
   */
  changeSceneChange: async function (sceneId, field, newValue, scriptId) {
    try {
      const body = JSON.stringify({
        sceneId: sceneId,
        field: field,
        newValue: newValue,
        scriptId: scriptId,
      });
      let url = `${process.env.REACT_APP_API_URL}/scripts/saveSceneChange`;
      const response = await fetch(url, postHeader(body));

      if (response.ok) {
        return response;
      } else {
        throw new Error(
          `Scene change failed with status ${response.status}: ${response.statusText}`
        );
      }
    } catch (error) {
      throw new Error(`Scene change failed: ${error.message}`);
    }
  },

  /**
   * Makes an asynchronous API call to the backend to save a change to a shot in a scene.
   *
   * @param {string} shotId - The ID of the shot to be updated.
   * @param {string} field - The field or property of the shot to be updated.
   * @param {any} newValue - The new value to be set for the specified field.
   * @param {string} sceneId - The ID of the scene to which the shot belongs.
   * @returns {Promise<Response>} - A Promise that resolves with the API response if successful, or rejects with an error if the API call fails.
   * @throws {Error} - Throws an error if the API call fails or encounters an exception.
   */
  changeShotChange: async function (shotId, field, newValue, sceneId) {
    try {
      const body = JSON.stringify({
        shotId: shotId,
        field: field,
        newValue: newValue,
        scene: sceneId,
      });
      let url = `${process.env.REACT_APP_API_URL}/scripts/saveShotChange`;
      const response = await fetch(url, postHeader(body));

      if (response.ok) {
        return response;
      } else {
        throw new Error(
          `Shot change failed with status ${response.status}: ${response.statusText}`
        );
      }
    } catch (error) {
      throw new Error(`Shot change failed: ${error.message}`);
    }
  },

  changeScriptLevel: async function (scriptId, level, userId, useCredits) {
    try {
      useCredits = useCredits || true;
      const body = JSON.stringify({
        scriptId: scriptId,
        level: level,
        userId: userId,
        useCredits: useCredits,
      });
      let url = `${process.env.REACT_APP_API_URL}/scripts/scriptLevelChange`;
      const response = await fetch(url, postHeader(body));

      if (response.ok) {
        let update = await response.json();
        return update;
      } else {
        throw new Error(
          `Failed to update script level with status ${response.status}: ${response.statusText}`
        );
      }
    } catch (error) {
      throw new Error(`Shot change failed: ${error.message}`);
    }
  },

  calculatePortion: function (scene) {
    const sceneLength = scene.scene_length || scene.size;
    const portionSize = 0.125;
    const portions = Math.ceil(sceneLength / portionSize);
    const wholes = Math.floor(portions / 8);
    const remainder = portions - wholes * 8;
    return { wholes, remainder };
  },
};

export default scriptService;
